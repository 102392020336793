//提现方式接口
import { axios } from '@/utils/request'
/*
 * @Description: 
 * @Version: 
 * @Author: 彭勃
 * @Date: 2022-02-11 13:15:50
 */
// 提现方式分页查询
export function tixianWayPageList(parameter){
    return axios({
        url: '/pfWithdrawalWay/page',
        method: 'post',
        params:parameter.page,
        data: parameter.queryParam
    })
}
//提现方式查询所有
export function tixianWayList(parameter){
    return axios({
        url: '/pfWithdrawalWay/list',
        method: 'post',
        data: parameter
    })
}
//提现方式详情
export function tixianWayDetail(parameter){
    return axios({
        url: '/pfWithdrawalWay/detail',
        method: 'post',
        params: parameter 
    })
}
//提现方式增加
export function tixianWayAdd(parameter){
    return axios({
        url: '/pfWithdrawalWay/add',
        method: 'post',
        data: parameter
    })
}
//提现方式删除
export function tixianWayDelete(parameter){
    return axios({
        url: '/pfWithdrawalWay/delete',
        method: 'post',
        data: parameter
    })
}
//提现方式编辑
export function tixianWayEdit(parameter){
    return axios({
        url: '/pfWithdrawalWay/edit',
        method: 'post',
        data: parameter
    })
}