<template>
  <a-modal
    title="新增"
    :width="500"
    :visible="visible"
    :confirmLoading="confirmLoading"
    :destroyOnClose="true"
    @ok="handleSubmit"
    @cancel="handleCancel"
  >
    <a-spin :spinning="confirmLoading">
      <a-row :gutter="24">
        <a-col :md="24" :sm="24">
          <a-form :form="form">
            <!-- 提现方式 -->
            <a-form-item label="提现方式" :labelCol="labelCol" :wrapperCol="wrapperCol" has-feedback>
              <a-input
                placeholder="请输入"
                v-decorator="['name', { rules: [{ required: true, message: '请输入！' }] }]"
              />
            </a-form-item>
            <!-- logo -->
            <a-form-item has-feedback label="logo" :labelCol="labelCol" :wrapperCol="wrapperCol">
              <div style="width: 104px; height: 104px">
                <a-upload
                  style="margin: auto"
                  name="avatar"
                  accept=".png,.jpg,jpeg,.gif"
                  list-type="picture-card"
                  :show-upload-list="false"
                  :customRequest="customUserRequest"
                  v-decorator="['picture', { rules: [{ required: true, message: '请上传！' }] }]"
                >
                  <img :src="picture" v-if="picture" style="width: 100px; height: 100px" />
                  <div v-else>
                    <a-icon :type="pictureLoading ? 'loading' : 'plus'" />
                  </div>
                </a-upload>
              </div>
            </a-form-item>
            <!-- 排序 -->
            <a-form-item label="排序" :labelCol="labelCol" :wrapperCol="wrapperCol" has-feedback>
              <a-input-number
                placeholder="请输入"
                :min="1"
                :max="1000"
                style="width: 40%; min-width: 100px"
                v-decorator="['sort', { rules: [{ required: true, message: '请输入！' }] }]"
              />
            </a-form-item>
          </a-form>
        </a-col>
      </a-row>
    </a-spin>
  </a-modal>
</template>

<script>
import { tixianWayAdd } from '@/api/modular/mallLiving/tixianWay'

import { getUploadScreat } from '@/api/modular/mallLiving/uploadScreat'
import COS from 'cos-js-sdk-v5'

export default {
  data() {
    return {
      loading: false,
      labelCol: {
        xs: { span: 24 },
        sm: { span: 6 },
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 16 },
      },
      visible: false,
      confirmLoading: false,
      form: this.$form.createForm(this),
      pictureLoading: false,
      picture: '',
      cos: null,
    }
  },

  methods: {
    // 初始化方法
    add() {
      let that = this
      let msgData = sessionStorage.getItem('upload-screat-msg')
      if (msgData) {
        msgData = JSON.parse(sessionStorage.getItem('upload-screat-msg'))
        var etime = new Date().getTime()
        if (msgData.expiredTime * 1000 < etime + 20 * 60 * 1000) {
          this.getUploadScreat()
        } else {
          let data = {
            TmpSecretId: msgData.tmpSecretId,
            TmpSecretKey: msgData.tmpSecretKey,
            XCosSecurityToken: msgData.sessionToken,
            StartTime: msgData.startTime,
            ExpiredTime: msgData.expiredTime,
          }
          that.cos = new COS({
            getAuthorization: function (options, callback) {
              callback(data)
            },
          })
        }
      } else {
        this.getUploadScreat()
      }
      //下面是正常的初始化
      this.visible = true
      this.picture = '' //将图片重置为空
    },
    handleSubmit() {
      this.confirmLoading = true
      this.form.validateFields((errors, values) => {
        if (!errors) {
          values.logo = this.picture
          delete values.picture
          tixianWayAdd(values).then((res) => {
            if (res.success) {
              this.$message.success('新增成功！')
              this.$emit('ok', values)
              this.confirmLoading = false
              this.handleCancel()
            } else {
              setTimeout(() => {
                this.confirmLoading = false
              }, 600)
            }
          })
        } else {
          this.confirmLoading = false
        }
      })
    },
    //图片上传，cos
    getUploadScreat() {
      let that = this
      getUploadScreat().then((res) => {
        that.uploadOption = res.data
        let data = {
          TmpSecretId: res.data.tmpSecretId,
          TmpSecretKey: res.data.tmpSecretKey,
          XCosSecurityToken: res.data.sessionToken,
          StartTime: res.data.startTime,
          ExpiredTime: res.data.expiredTime,
        }
        sessionStorage.setItem('upload-screat-msg', JSON.stringify(res.data))
        that.cos = new COS({
          getAuthorization: function (options, callback) {
            callback(data)
          },
        })
      })
    },
    formatFileName() {
      var t = 'ABCDEFGHJKMNPQRSTWXYZabcdefhijkmnprstwxyz2345678',
        a = t.length,
        n = ''

      for (var i = 0; i < 10; i++) n += t.charAt(Math.floor(Math.random() * a))
      return n + new Date().getTime()
    },
    //自定义图片上传实现
    customUserRequest(filedata) {
      let that = this
      this.pictureLoading = true
      console.log(this.cos)
      console.log(filedata)
      that.cos.putObject(
        {
          // Bucket: 'chanbow-1309518477' /* 必须 */,
          Bucket: 'cos-1311593864',
          Region: 'ap-nanjing' /* 存储桶所在地域，必须字段 */,
          Key:
            that.formatFileName() +
            '.' +
            filedata.file.name.split('.')[filedata.file.name.split('.').length - 1] /* 必须 */,
          StorageClass: 'STANDARD',
          Body: filedata.file, // 上传文件对象
          onProgress: (progressData) => {},
        },
        (err, data) => {
          // 将上传后的封面进行路径拼接保存到本地
          if (data.Location) {
            that.picture = 'https://' + data.Location
            that.$nextTick(function () {
              that.$forceUpdate()
            })
          }
          this.pictureLoading = false
        }
      )
    },
    handleCancel() {
      this.visible = false //关闭对话框
      this.confirmLoading = false
      this.form.resetFields() //重置表单
    },
  },
}
</script>
<style scoped>
.flex {
  display: flex;
}
.ant-upload-select-picture-card i {
  font-size: 32px;
  color: #999;
}

.ant-upload-select-picture-card .ant-upload-text {
  margin-top: 8px;
  color: #666;
}
</style>
